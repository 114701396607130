import React, { useState } from "react";
import { Container, Carousel, Row, Col } from "react-bootstrap";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import Star from "../images/star.svg";
import Accordion from "react-bootstrap/Accordion";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
import AccordianNew from "./custom-accordian";

const FaqPageFaqs = ({ title, description, faqs, bgColour }) => {
	// Function to handle the accordion state

	const faqData = faqs.map((faq) => ({
		question: faq.faqFields.question,
		answer: faq.faqFields.answer,
	}));

	return (
		<Container className="py-5 py-lg-7">
			<Row className="justify-content-center  gx-5  ">
				<Col className="" lg={4}>
					<div className=" ">
						{parse(title)}
						<p className="py-3">{description}</p>
					</div>
				</Col>
				<Col lg={8}>
					<AccordianNew bgColour={bgColour} faqs={faqData} />
				</Col>
			</Row>
		</Container>
	);
};

export default FaqPageFaqs;
