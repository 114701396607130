import { Link, useStaticQuery, graphql, withPrefix } from "gatsby";
import React, {
	useContext,
	useState,
	useEffect,
	useRef,
	createRef,
} from "react";
import Container from "react-bootstrap/Container";
import {
	ButtonToolbar,
	Col,
	InputGroup,
	Row,
	ToggleButton,
	ToggleButtonGroup,
} from "react-bootstrap";
import Layout from "../components/layout";
import Button from "react-bootstrap/Button";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import { GatsbySeo } from "gatsby-plugin-next-seo";
// import LocalBusinessSchema from "../components/schema/localBusinessSchema";
import { Helmet } from "react-helmet";
import gsap from "gsap";
import { motion } from "framer-motion";
import { useForm, ValidationError } from "@formspree/react";
import Form from "react-bootstrap/Form";

import { Card } from "react-bootstrap";
import RecordForm from "../components/record-form";
import MultiStepForm from "../components/multi-step-form";
import FaqSection from "../components/faq-section";
import BrokerSignUpForm from "../components/broker-sign-up-form";
import FaqPageFaqs from "../components/faq-page-faqs";
import FaqMessageComponent from "../components/faq-message-component";

const FaqPage = () => {
	const {
		wpFaqPage: {
			seoFields,
			faqPageFields: { heroSection, formSection },
		},
		allWpFaq: { nodes: faqs },
		site: {
			siteMetadata: { siteUrl },
		},
	} = useStaticQuery(graphql`
		query {
			wpFaqPage {
				seoFields {
					metaTitle
					opengraphTitle
					opengraphDescription
					metaDescription
					image {
						altText
						link
						localFile {
							childImageSharp {
								original {
									height
									width
								}
							}
						}
					}
				}
				faqPageFields {
					heroSection {
						title
						description
					}
					formSection {
						title
					}
				}
			}
			allWpFaq {
				nodes {
					faqFields {
						question
						answer
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Faqs",
				item: {
					url: `${siteUrl}/faqs`,
					id: `${siteUrl}/faqs`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title={seoFields.metaTitle}
				description={seoFields.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/faqs`,
					title: seoFields.opengraphTitle,
					description: seoFields.opengraphDescription,
					images: [
						{
							url: `${seoFields.image?.link}`,
							width: `${seoFields.image?.localFile.childImageSharp.original.width}`,
							height: `${seoFields.image?.localFile.childImageSharp.original.height}`,
							alt: `${seoFields.image?.altText}`,
						},
					],
				}}
			/>

			<div>
				<Layout>
					<section className="bg-med-grey  py-lg-7">
						<FaqPageFaqs
							bgColour="#F5F5F5"
							title={heroSection.title}
							description={heroSection.description}
							faqs={faqs}
						/>
					</section>
					{/* <section
						style={{
							borderTop: "1px solid #C4D6E4",
							borderBottom: "1px solid #C4D6E4",
						}}
						className=" py-4 py-md-5 py-lg-7"
					>
						<FaqMessageComponent title={formSection.title} />
					</section> */}
				</Layout>
			</div>
		</>
	);
};

export default FaqPage;
